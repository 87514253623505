<template>
  <validation-provider
    v-slot="{ errors }"
    :vid="vidName"
    name="Country"
  >
    <v-select
      v-model="selectedCountry"
      :options="countries"
      :clearable="false"
      label="text"
      :reduce="country => country.value"
      :placeholder="$t('select_a_country')"
      :disabled="!isEditable"
      @input="handleSetSelectedCountry"
    />
    <span class="text-danger">{{ errors[0] }}</span>
  </validation-provider>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'MySelectCountries',
  components: {
    vSelect,
    ValidationProvider,

  },
  props: {
    country: {
      type: String,
      default: '',
    },
    vidName: {
      type: String,
      default: 'country',
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      countries: [
        { text: 'Malaysia', value: 'Malaysia' },
        { text: 'Singapore', value: 'Singapore' },
      ],
      selectedCountry: null,
    }
  },
  watch: {
    country(newValue) {
      this.selectedCountry = newValue
    },
  },
  mounted() {
    this.selectedCountry = this.country
  },
  methods: {
    handleSetSelectedCountry() {
      this.$emit('set-selected-country', this.selectedCountry)
    },
  },
}
</script>
